import React from "react";
import { useAppContext } from "../../utils/AppContext";
import LineChart from "../common/LineChart";
import { Bar } from 'react-chartjs-2';

const dummyData = {
  "_id": "82aa2404-5774-468a-98f7-694f33f965c6",
  "email": "larryneilmcgowen1206@gmail.com",
  "request_count": 2,
  "credit": 9.998,
  "created_date": "2023-10-01T18:51:40.850000",
  "api_keys": [
    {
      "key": "3ac22aac-8413-47f8-976b-29f72a845533",
      "created": "2024-10-01T18:51:40.850000"
    }
  ],
  "usage": [
    {
      "model_name": "JuggernautXL",
      "pipeline_type": "txt2img",
      "credit_cost": 0.001,
      "timestamp": "2023-12-01T18:52:50.570000"
    },
    {
      "model_name": "JuggernautXL",
      "pipeline_type": "txt2img",
      "credit_cost": 0.001,
      "timestamp": "2024-02-01T21:19:56.753000"
    },
    {
      "model_name": "JuggernautXL",
      "pipeline_type": "txt2img",
      "credit_cost": 0.001,
      "timestamp": "2023-02-01T21:19:56.753000"
    },
    {
      "model_name": "JuggernautXL",
      "pipeline_type": "gojourney",
      "credit_cost": 0.001,
      "timestamp": "2024-09-01T21:19:56.753000"
    },
    {
      "model_name": "JuggernautXL",
      "pipeline_type": "txt2img",
      "credit_cost": 0.001,
      "timestamp": "2024-09-01T22:19:56.753000"
    },
    {
      "model_name": "JuggernautXL",
      "pipeline_type": "txt2img",
      "credit_cost": 0.001,
      "timestamp": "2024-09-01T23:19:56.753000"
    },
    {
      "model_name": "JuggernautXL",
      "pipeline_type": "txt2img",
      "credit_cost": 0.001,
      "timestamp": "2024-10-01T21:19:56.753000"
    },
    {
      "model_name": "JuggernautXL",
      "pipeline_type": "gojourney",
      "credit_cost": 0.001,
      "timestamp": "2024-10-01T22:19:56.753000"
    },
    {
      "model_name": "JuggernautXL",
      "pipeline_type": "gojourney",
      "credit_cost": 0.001,
      "timestamp": "2024-10-02T05:19:56.753000"
    }
  ]
}

const GraphsSection: React.FC = () => {
  const { userInfo } = useAppContext();

  const usageData = userInfo.usage
  // const usageData = dummyData.usage

  // Prepare data for the line graph
  const months = Array.from({ length: 12 }, (_, i) => new Date(new Date().setMonth(new Date().getMonth() - i)).toLocaleString('default', { month: 'long' })).reverse();
  const creditValues: number[] = new Array(12).fill(userInfo?.credit);
  const currentMonth = new Date().getMonth();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  for (let index = 10; index >= 0; index--) {
    const monthUsage = usageData.filter(usage => {
      const usageDate = new Date(usage.timestamp);
      return usageDate.getMonth() === ((12 + currentMonth - (10 - index)) % 12) && usageDate >= oneYearAgo;
    });
    const monthTransactions = userInfo?.balance_history.filter(transaction => {
      const transactionDate = new Date(transaction.timestamp);
      return transactionDate.getMonth() === ((12 + currentMonth - (10 - index)) % 12) && transactionDate >= oneYearAgo;
    });
    const totalUsed = monthUsage.reduce((acc, usage) => acc + usage.credit_cost, 0);
    const totalTopUp = monthTransactions.reduce((acc, transaction) => acc + transaction.amount, 0);
    const previousValue = creditValues[index + 1];
    creditValues[index] = previousValue + totalUsed - totalTopUp;
  }

  const createdDate = new Date(userInfo?.created_date || '');
  const currentDate = new Date();
  const monthDifference = (currentDate.getFullYear() - createdDate.getFullYear()) * 12 + (currentDate.getMonth() - createdDate.getMonth());
  if (monthDifference < 10) {
    // Reduce months and creditValues to match the actual data
    months.splice(0, 10 - monthDifference);
    creditValues.splice(0, 10 - monthDifference);
  }

  const yearData = {
    labels: months,
    datasets: [
      {
        label: 'Balance',
        data: creditValues,
        borderColor: '#001AFF',
        backgroundColor: 'rgba(0, 26, 255, 0.2)',
        fill: true,
      },
    ],
  };

  // Prepare data for the real-time graph
  const currentTime = new Date();
  const times = Array.from({ length: 23 }, (_, i) => {
    const hours = (23 + currentTime.getHours() - i) % 24;
    return `${hours.toString().padStart(2, '0')}:00`;
  }).reverse();
  
  times.push(`${currentTime.getHours().toString().padStart(2, '0')}:${currentTime.getMinutes().toString().padStart(2, '0')}`);
  
  const creditRealtimeData: number[] = new Array(24).fill(userInfo?.credit);
  const currentHour = new Date().getHours();
  const oneDayAgo = new Date();
  oneDayAgo.setHours(oneDayAgo.getHours() - 24);
  for (let index = 22; index >= 0; index--) {
    const hourUsage = usageData.filter(usage => {
      const usageDate = new Date(usage.timestamp + 'Z');
      return usageDate.getHours() === ((24 + currentHour - (22 - index)) % 24) && usageDate >= oneDayAgo;
    });
    const hourTransactions = userInfo?.balance_history.filter(transaction => {
      const transactionDate = new Date(transaction.timestamp + 'Z');
      return transactionDate.getHours() === ((24 + currentHour - (22 - index)) % 24) && transactionDate >= oneDayAgo;
    });
    const totalUsed = hourUsage.reduce((acc, usage) => acc + usage.credit_cost, 0);
    const totalTopUp = hourTransactions.reduce((acc, transaction) => acc + transaction.amount, 0);
    const previousValue = creditRealtimeData[index + 1];
    creditRealtimeData[index] = previousValue + totalUsed - totalTopUp;
  }

  const dayTimeData = {
    labels: times,
    datasets: [
      {
        label: 'Balance',
        data: creditRealtimeData,
        borderColor: '#001AFF',
        backgroundColor: 'rgba(0, 26, 255, 0.2)',
        fill: true,
      },
    ],
  };

  // Define the type for endpointUsageData
  const endpoints = usageData.map(usage => usage.pipeline_type);
  const uniqueEndpoints = Array.from(new Set(endpoints));
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const endpointUsageData: { [key: string]: number } = {};
  for (let index = 0; index < uniqueEndpoints.length; index++) {
    endpointUsageData[uniqueEndpoints[index]] = usageData.filter(usage => usage.pipeline_type === uniqueEndpoints[index]).length;
  }
  // Prepare data for the bar chart
  const barChartData = {
    labels: uniqueEndpoints,
    datasets: [
      {
        label: 'Usage Count',
        data: uniqueEndpoints.map(endpoint => endpointUsageData[endpoint]),
        backgroundColor: 'rgba(0, 123, 255, 0.5)',
      },
    ],
  };

  return (
    <section className="overflow-hidden w-[790px] px-5 md:px-10">
      <div className="w-full rounded-[16px] md:rounded-[30px] p-8 bg-white mt-14 flex flex-col items-start">
        <p className="font-semibold text-[20px]">Balance</p>
        <p className="font-bold text-[40px] mt-4">${userInfo?.credit}</p>
        <p className="font-normal text-[14px] text-[#00000080]">USD</p>
        <div className="w-full border border-solid border-[#F2F2F2] mt-4"></div>
        <p className="font-medium text-[14px] mt-4">Track your spend for each month</p>
        <div className="w-full rounded-[15px] mt-4">
          <LineChart data={yearData} />
        </div>
      </div>
      <div className="w-full rounded-[16px] md:rounded-[30px] p-8 bg-white mt-4 flex flex-col items-start">
        <p className="font-semibold text-[20px]">Current Account Balance</p>
        <p className="font-normal text-[14px] text-[#00000080]">Your account balance in real-time</p>
        <div className="w-full mt-4">
          <LineChart data={dayTimeData} />
        </div>
      </div>
      <div className="w-full rounded-[16px] md:rounded-[30px] p-8 bg-white my-4 flex flex-col items-start">
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col items-start">
            <p className="font-semibold text-[14px] sm:text-[20px]">Current Usage by Endpoint</p>
            <p className="font-normal text-[12px] sm:text-[14px] text-[#00000080]">Track endpoints usage in real-time</p>
          </div>
          <div className="flex py-1 sm:py-2 px-[6px] sm:px-4 rounded-[100px] text-[#001AFF] text-[12px] sm:text-[14px] bg-[#e5e7eb]">
            <p className="text-center">Since {new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}, {new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString('default', { day: 'numeric', month: 'long' })}</p>
          </div>
        </div>
        <Bar data={barChartData} options={{ responsive: true }} />
      </div>
    </section>
  )
}

export default GraphsSection;
