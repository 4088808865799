import React, { useCallback, useEffect, useState } from "react";
import RobotImg from '../../assets/images/Robot Head2 1.png';
import DeleteImg from '../../assets/images/delete.svg';
import CopyImg from '../../assets/images/copy.svg';
import CopySuccessImg from '../../assets/images/copy-success.svg';
import EmailImg from '../../assets/images/small-email.svg';
import Modal from "../common/Modal";
import Input from "../common/Input";
import KeyIcon from '../../assets/images/key-icon.svg';  // Make sure to add this import
import { useAppContext } from "../../utils/AppContext";
import { getCurrentDateString } from "../../utils/services";
import { addUserAPIKey, deleteUserAPIKey } from "../../utils/apiServices";
import ConfirmModal from "../modals/ConfirmModal";
import { ModalType } from "../../utils/types";
import AddToBalanceModal from "./AddToBalanceModal";

const numberOfAPIKey = 5;

interface IApiKey {
  key: string;
  created: string;
  isCopied: boolean;
}

const DashboardSection: React.FC = () => {
  const [apiKeys, setApiKeys] = useState<Array<IApiKey>>([]);
  const [openAddBalanceModal, setOpenAddBalanceModal] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const { userInfo, setUserInfo, setModalStatus, isConfirmDeleteAPIKey, setIsConfirmDeleteAPIKey } = useAppContext();
  const [isHovering, setIsHovering] = useState(false);
  const [apiKeyToDelete, setApiKeyToDelete] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userInfo && userInfo.api_keys) {
      setApiKeys(userInfo.api_keys?.map(key => ({
        key: key.key,
        created: key.created,
        isCopied: false
      })) || [])
    }
  }, [userInfo, userInfo.api_keys])

  const handleAddAPIKey = useCallback(async () => {
    setIsLoading(true);
    const updatedUser = await addUserAPIKey(userInfo?.api_keys?.[0].key || "")
    setUserInfo({
      email: updatedUser.user.email,
      api_keys: [{
        key: updatedUser.user._id,
        created: updatedUser.user.created_date || ""
      }, ...(updatedUser.user.api_keys || [])],
      credit: updatedUser.user.credit,
      request_count: updatedUser.user.request_count,
      created_date: updatedUser.user?.created_date || '',
      usage: updatedUser.user.usage || [],
      balance_history: updatedUser.user.balance_history || []
    });
    setIsLoading(false);
  }, [userInfo])

  useEffect(() => {
    if (isConfirmDeleteAPIKey) {
      handleConfirmDelete()
    }
  }, [isConfirmDeleteAPIKey])

  const handleDeleteAPIKey = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const apiKey = e.currentTarget.id;
    setApiKeyToDelete(apiKey); // Set the API key to delete
    setModalStatus(ModalType.CONFIRM_DELETE); // Open the confirmation modal
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (apiKeyToDelete) {
      const updatedUser = await deleteUserAPIKey(userInfo?.api_keys?.[0].key || "", apiKeyToDelete);
      setUserInfo({
        email: updatedUser.user.email,
        api_keys: [{
          key: updatedUser.user._id,
          created: updatedUser.user.created_date || ""
        }, ...(updatedUser.user.api_keys || [])],
        credit: updatedUser.user.credit,
        request_count: updatedUser.user.request_count,
        created_date: updatedUser.user?.created_date || '',
        usage: updatedUser.user.usage || [],
        balance_history: updatedUser.user.balance_history || []
      });
    }
    setModalStatus(ModalType.NONE);
    setIsConfirmDeleteAPIKey(false);
  }, [apiKeyToDelete])

  const handleAPIKeyCopy = (key: string) => {
    navigator.clipboard.writeText(key).then(
      () => {
        setApiKeys(apiKeys.map(value => ({
          ...value,
          isCopied: value.key === key
        })));
        setTimeout(() => setApiKeys(apiKeys.map(value => ({
          ...value,
          isCopied: false,
        }))), 2000);
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  };

  const handleMaskString = (key: string, maskedLength: number) => {
    const start = key.slice(0, 4);
    const end = key.slice(-5);
    const maskChar = "*";
    const masked = maskChar.repeat(maskedLength);

    return `${start}${masked}${end}`;
  }

  return (
    <section className="md:px-10 overflow-hidden w-[790px]" >
      <div className="relative w-full h-[131px] bg-white mt-14 rounded-[16px] md:rounded-[30px] block md:flex md:justify-center md:items-center p-5 sm:p-10">
        <div className="w-[40%] sm:w-1/2 flex flex-col">
          <p className="font-bold text-[12px] sm:text-[20px] text-black uppercase text-start">Affordable Image&Text</p>
          <p className="font-bold text-[12px] sm:text-[20px] text-black uppercase text-start sm:mt-[-10px]">Generation API</p>
          <p className="text-[12px] text-[#00000080] mt-[8px] text-start">That supports the latest models</p>
        </div>
        <div className="absolute md:relative right-[-70px] bottom-0 flex justify-center w-[100%] md:w-[60%] sm:w-1/2">
          <img src={RobotImg} alt="robot" className="w-[248px] mt-[-35px] mr-[-40px] sm:mr-0" />
        </div>
      </div>
      <div className="mt-5 block md:flex gap-3">
        <div className="w-full md:w-1/2 h-[260px] md:h-[310px] p-5 sm:p-10 rounded-[16px] md:rounded-[30px] bg-white flex flex-col items-start">
          <p className="font-semibold text-[20px]">Balance</p>
          <p className="font-bold text-[40px] mt-8 md:mt-16">${userInfo?.credit}</p>
          <p className="font-bold text-[14px] mt-2 text-[#00000080]">USD</p>
          <div className="w-full mt-5 flex justify-center">
            <AddToBalanceModal
              isOpen={openAddBalanceModal}
              onClose={() => setOpenAddBalanceModal(false)}
            />
            <button
              className="py-4 px-6 text-white bg-[#001AFF] hover:bg-blue-700 rounded-[100px] w-full h-[50px] flex items-center justify-center"
              onClick={() => window.open(process.env.REACT_APP_STRIPE_PAYMENT_LINK, '_blank')}
            >
              Add balance
            </button>
          </div>
        </div>
        <div className="w-full md:w-1/2 h-[260px] md:h-[310px] p-5 sm:p-10 rounded-[16px] md:rounded-[30px] bg-white flex flex-col items-start mt-5 md:mt-0">
          <p className="font-semibold text-[20px]">Account Level</p>
          <p className="font-bold text-[40px] mt-8 md:mt-16">Standard</p>
          <div className="w-full mt-12 justify-center">
            <Modal isOpen={openUpgradeModal} onClose={() => setOpenUpgradeModal(false)}>
              <div className="w-full h-[85px] rounded-[15px] bg-[#F2F2F2] mt-16 relative">
                <img src={RobotImg} alt="robot" className="absolute bottom-0 w-[218px] left-[20%]" />
              </div>
              <div className="mt-6">
                <p className="font-normal text-[14px] text-[#00000080]">API Platform</p>
              </div>
              <div className="flex justify-between items-center mt-3">
                <p className="font-bold text-[31px]">Upgrade to Enterprise</p>
              </div>
              <div className="mt-3">
                <p className="text-[14px] text-[#00000080]">To get started with higher rate-limits, more keys and personalised support, get in touch below.</p>
              </div>
              <div className="w-full mt-6 flex justify-center">
                <a
                  href="mailto:developers@nichetensor.com"
                  className="py-4 px-6 text-white bg-[#001AFF] hover:bg-blue-700 rounded-[100px] w-full h-[50px] flex items-center justify-center text-[14px] no-underline"
                >
                  <img src={EmailImg} alt="email" className="mr-2" />
                  developers@nichetensor.com
                </a>
              </div>
            </Modal>
            <button
              className="py-4 px-6 text-white bg-black hover:bg-slate-700 rounded-[100px] w-full h-[50px] flex items-center justify-center"
              onClick={() => setOpenUpgradeModal(true)}
            >
              Upgrade
            </button>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="flex justify-between items-center mb-5">
          <h2 className="font-semibold text-[20px]">API Keys {apiKeys.length >= numberOfAPIKey - 1 && ` (${apiKeys.length}/${numberOfAPIKey})`}</h2>
          <div
            className="relative"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <button
              className={`py-2 px-4 text-white rounded-[100px] text-[14px] ${apiKeys.length >= numberOfAPIKey ? 'bg-gray-400' : 'bg-[#001AFF] hover:bg-blue-700'
                }`}
              onClick={handleAddAPIKey}
              disabled={apiKeys.length >= numberOfAPIKey || isLoading}
            >
              Add API Key
            </button>
            {apiKeys.length >= numberOfAPIKey && isHovering && (
              <div className="absolute top-full right-0 mt-2 px-4 py-2 text-[14px] text-[#00000080] bg-white rounded-[16px] whitespace-nowrap shadow-md">
                Please upgrade to enterprise for more keys
              </div>
            )}
          </div>
        </div>
        <div className="w-full bg-white rounded-[16px] md:rounded-[30px] p-5">
          {apiKeys.map((value, idx) => (
            <div key={value.key} className="flex justify-between items-center py-4 border-b border-gray-200 last:border-b-0">
              <div className="flex items-center space-x-4">
                <div className="w-10 h-10 bg-[#F2F2F2] rounded-full flex items-center justify-center">
                  <img src={KeyIcon} alt="API Key" className="w-5 h-5 text-[#001AFF]" />
                </div>
                <div className="flex items-center space-x-2">
                  <p className="font-semibold text-[14px]">API Key {idx + 1}</p>
                  <span className="text-[12px] text-[#00000080]">•</span>
                  <p className="text-[12px] text-[#00000080]">
                    {new Date(value.created).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).replace(',', '')}
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <p className="text-[14px] font-mono">{handleMaskString(value.key, 12)}</p>
                <button
                  onClick={() => handleAPIKeyCopy(value.key)}
                  className="p-2 rounded-full transition-colors hover:bg-gray-100"
                >
                  <img src={value.isCopied ? CopySuccessImg : CopyImg} alt="copy" className="w-5 h-5 text-black" />
                </button>
                {idx > 0 ? (<button
                  id={value.key}
                  onClick={handleDeleteAPIKey}
                  className="p-2 rounded-full transition-colors hover:bg-gray-100"
                >
                  <img src={DeleteImg} alt="delete" className="w-5 h-5 text-black" />
                </button>) : <div className="w-[36px]"> </div>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default DashboardSection;