export interface IUserInfo {
  email: string;
  credit: number; // number of credits
  request_count: number; // number of requests made
  created_date?: string;
  api_keys?: Array<{
    key: string;
    created: string;
  }>;
  usage: Array<{
    model_name: string;
    pipeline_type: string;
    credit_cost: number;
    timestamp: string;
  }>;
  balance_history: Array<{
    amount: number;
    timestamp: string;
  }>;
}

export enum ModalType {
  SIGNIN = 'signIn',
  SIGNUP = 'signUp',
  CHANGE_PASSWORD = 'changePassword',
  CONFIRM_DELETE = 'confirmDelete',
  NONE = '',
}
